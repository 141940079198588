import gql from "graphql-tag";

export const getOrganizationMembersWithAggregation = gql`
  query GetOrganizationMembersWithAggregation(
    $organizationId: String
    $paginationArguments: PaginationArgumentsForwardInput
  ) {
    GetOrganizationMembersWithAggregation(
      input: { organizationId: $organizationId, paginationArguments: $paginationArguments }
    ) {
      connection {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            relation
            groupsCount
            projectsCount
            status
            membersResponse {
              memberStatus
              role {
                systemRole
                description
                organizationId
              }
              user {
                email
                userId
                memberId
                photoUrl
                displayName
                memberId
              }
            }
          }
        }
      }
    }
  }
`;
