import gql from "graphql-tag";

export const getManyInvitations = gql`
  query GetManyInvitations($input: GetManyInvitationsRequestInput) {
    GetManyInvitations(input: $input) {
      invitations {
        organizationId
        organizationName
        invitedBy {
          userId
          email
          displayName
          photoUrl
          memberId
        }
      }
    }
  }
`;
