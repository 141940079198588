import produce from "immer";
import {
  addOrUpdateMemberFromState,
  createMember,
  deleteMember,
  getUserIdWithEmailAddress,
  updateMember,
} from "./common";
import {
  IActivateMembershipFromInvitationCodeRequestInput,
  IActivateMembershipRequestInput,
  IAssignRoleResponse,
  IDeActivateOrganizationMemberRequestInput,
  IGetManyInvitationsRequestInput,
} from "../../interfaces/generated";
import { getOrganizationMembers } from "../../graphql/queries/getOrganizationMembers";
import { queryWithStaleCache } from "../../graphql/middleware/query-with-stale-cache";
import {
  ICreateOrganizationMemberInput,
  IDeleteOrganizationMemberInput,
  IUpdateOrganizationMemberInput,
  IUseOrganizationMemberState,
} from "./types";
import { client } from "../../utils/client";
import { getOrganizationMembersWithAggregation } from "../../graphql/queries/getOrganizationMembersWithAggregation.gql";
import { getManyInvitations } from "../../graphql/queries/getManyInvitations.gql";
import { activateMembership } from "../../graphql/mutations/activateMembership.gql";
import { activateMembershipFromInvitationCode } from "../../graphql/mutations/activateMembershipFromInvitationCode.gql";
import { deActivateOrganizationMemberState } from "../../graphql/mutations/deActivateOrganizationMemberState.gql";

export const createOrganizationMemberSlice = (
  set: (cb: (state: IUseOrganizationMemberState) => IUseOrganizationMemberState, boolean, string) => void,
  get: () => IUseOrganizationMemberState,
) => ({
  organizationMember: {
    isLoading: false,
    dataHash: undefined,
    members: {},
    membersWithAggregation: {},
    invitations: [],
    loadingMembersWithAggregation: false,
    loadingInvitations: false,
    loadOrganizationMembers: async ({ organizationId }) => {
      await queryWithStaleCache({
        uniquePrefix: "organization-members",
        query: getOrganizationMembers,
        variables: { organizationId },
        onCacheMiss: () => {
          set(
            produce((state) => {
              state.organizationMember.isLoading = true;
            }),
            false,
            "organizationMember/getOrganizationMembers/start",
          );
        },
        onUpdate: ({ data, dataHash }) => {
          if (data.GetOrganizationMembers) {
            set(
              produce((state: IUseOrganizationMemberState) => {
                state.organizationMember.isLoading = false;
                if (get().organizationMember.dataHash != dataHash) {
                  state.organizationMember.dataHash = dataHash;
                  data.GetOrganizationMembers.connection.edges.forEach((member) => {
                    const newMember = {
                      organizationId,
                      roleId: member.node.role.roleId,
                      systemRole: member.node.role.systemRole,
                      emailAddress: member.node.user.email,
                      userId: member.node.user.userId,
                    };
                    addOrUpdateMemberFromState(
                      member.node.user.userId,
                      newMember,
                      state.organizationMember.members,
                      organizationId,
                    );
                  });
                }
              }),
              false,
              "organizationMember/getOrganizationMembers/done",
            );
          }
        },
      });
    },
    createOrganizationMember: async ({
      organizationId,
      roleId,
      systemRole,
      emailAddress,
    }: ICreateOrganizationMemberInput): Promise<IAssignRoleResponse | undefined> => {
      const data = await createMember({ organizationId, roleId, systemRole, emailAddress });

      const key = data?.userId;

      if (key) {
        const newMember = {
          roleId,
          systemRole,
          emailAddress,
          userId: data.userId,
          organizationId,
        };
        const stateKey = organizationId;

        set(
          produce((state: IUseOrganizationMemberState) => {
            addOrUpdateMemberFromState(key, newMember, state.organizationMember.members, stateKey);
          }),
          false,
          "organizationMember/createOrganizationMember",
        );
      }

      return data;
    },
    deleteOrganizationMember: async ({ organizationId, userId, emailAddress }: IDeleteOrganizationMemberInput) => {
      const data = await deleteMember({ organizationId, userId, emailAddress });

      const key = userId || getUserIdWithEmailAddress(emailAddress, get().organizationMember.members[organizationId]);

      set(
        produce((state: IUseOrganizationMemberState) => {
          if (state.organizationMember.members?.[organizationId]?.[key]) {
            delete state.organizationMember.members[organizationId][key];
          }

          if (state.organizationMember.membersWithAggregation?.[organizationId]?.[key]) {
            delete state.organizationMember.membersWithAggregation[organizationId][key];
          }
        }),
        false,
        "organizationMember/createOrganizationMember",
      );

      return data;
    },
    updateOrganizationMember: async ({
      organizationId,
      userId,
      roleId,
      systemRole,
      emailAddress,
      memberId,
    }: IUpdateOrganizationMemberInput) => {
      const data = await updateMember({ organizationId, userId, roleId, systemRole, emailAddress, memberId });

      const key = data.userId || userId || data.memberId || memberId;

      if (key) {
        const id = userId || data.userId;
        const email = get().organizationMember.members?.[organizationId]?.[key]?.emailAddress;
        const updatedMember = {
          roleId,
          systemRole,
          emailAddress: email,
          userId: id,
          organizationId,
        };
        const stateKey = organizationId;
        set(
          produce((state: IUseOrganizationMemberState) => {
            if (state.organizationMember.members?.[organizationId]) {
              addOrUpdateMemberFromState(key, updatedMember, state.organizationMember.members, stateKey);
            }

            if (state.organizationMember.membersWithAggregation[organizationId]) {
              state.organizationMember.membersWithAggregation[stateKey][key].membersResponse.role.roleId = roleId;
              state.organizationMember.membersWithAggregation[stateKey][key].membersResponse.role.systemRole =
                systemRole;
            }
          }),
          false,
          "organizationMember/createOrganizationMember",
        );
      }

      return data;
    },
    getOrganizationMembers: ({ organizationId }) => {
      return get().organizationMember.members[organizationId];
    },
    loadOrganizationMembersWithAggregation: async ({ organizationId }) => {
      set(
        produce((state) => {
          state.organizationMember.loadingMembersWithAggregation = true;
        }),
        false,
        "organizationMember/loadOrganizationMembersWithAggregation/start",
      );

      const { data } = await client.query({
        query: getOrganizationMembersWithAggregation,
        variables: {
          organizationId,
        },
        fetchPolicy: "no-cache",
      });

      set(
        produce((state) => {
          if (!state.organizationMember.membersWithAggregation[organizationId]) {
            state.organizationMember.membersWithAggregation[organizationId] = {};
          }
          data.GetOrganizationMembersWithAggregation.connection.edges.forEach((edge) => {
            const key = edge.node.membersResponse.user.memberId || edge.node.membersResponse.user.userId;
            state.organizationMember.membersWithAggregation[organizationId][key] = edge.node;
          });
          state.organizationMember.loadingMembersWithAggregation = false;
        }),
        false,
        "organizationMember/loadOrganizationMembersWithAggregation/end",
      );
    },
    getManyInvitations: async (input: IGetManyInvitationsRequestInput) => {
      set(
        produce((state) => {
          state.organizationMember.loadingInvitations = true;
        }),
        false,
        "organizationMember/getManyInvitations/start",
      );

      const { data } = await client.query({
        query: getManyInvitations,
        variables: { input },
      });

      if (data.GetManyInvitations.invitations) {
        set(
          produce((state) => {
            state.organizationMember.invitations = data.GetManyInvitations.invitations;
            state.organizationMember.loadingInvitations = false;
          }),
          false,
          "organizationMember/getManyInvitations",
        );
      }
    },
    activateMembership: async (input: IActivateMembershipRequestInput) => {
      await client.mutate({
        mutation: activateMembership,
        variables: { input },
      });

      set(
        produce((state) => {
          state.organizationMember.invitations = state.organizationMember.invitations.filter(
            (invitation) => invitation.organizationId !== input.organizationId,
          );
        }),
        false,
        "organizationMember/activateMembership",
      );
    },
    activateMembershipFromInvitationCode: async (input: IActivateMembershipFromInvitationCodeRequestInput) => {
      await client.mutate({
        mutation: activateMembershipFromInvitationCode,
        variables: { input },
      });
    },
    deActivateOrganizationMemberState: async (input: IDeActivateOrganizationMemberRequestInput) => {
      await client.mutate({
        mutation: deActivateOrganizationMemberState,
        variables: { input },
      });

      set(
        produce((state) => {
          state.organizationMember.membersWithAggregation[input.organizationId][
            input.userId
          ].membersResponse.memberStatus = "deactivated";
        }),
        false,
        "organizationMember/deActivateOrganizationMemberState",
      );
    },
  },
});
