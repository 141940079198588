import gql from "graphql-tag";

export const getProjectMembersWithAggregation = gql`
  query GetProjectMembersWithAggregation($projectId: String, $paginationArguments: PaginationArgumentsForwardInput) {
    GetProjectMembersWithAggregation(input: { projectId: $projectId, paginationArguments: $paginationArguments }) {
      connection {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            relation
            status
            membersResponse {
              memberStatus
              roles {
                systemRole
                description
                organizationId
                groupId
                projectId
              }
              user {
                email
                userId
                memberId
                photoUrl
                displayName
              }
            }
          }
        }
      }
    }
  }
`;
