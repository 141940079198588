import { useEffect } from "react";
import { CLIENT_EVENTS } from "../../../constants/clientEvents";
import { useCurrentProjectId } from "src/hooks/current-project-id/useCurrentProjectId";
import { IPreviewFieldActiveBody } from "./types";
import { useRouter } from "next/router";

export const ListenerPreviewEdit = () => {
  const currentProjectId = useCurrentProjectId();
  const router = useRouter();

  useEffect(() => {
    const handler = (e: any) => {
      const { projectId, documentId, locale, fieldName }: IPreviewFieldActiveBody = e.detail;

      if (currentProjectId !== projectId || !documentId) return;

      // http://localhost:3000/app/project/documents/detail?project_id=54a99dd3-388e-477f-8d0c-02c337b6a26c&document_id=78626432-3935-4564-3465-388b671923b6
      const url = `/project/documents/detail?project_id=${projectId}&document_id=${documentId}&active_field_name=${fieldName}&active_locale_name=${locale}`;
      router.push(url, undefined, {
        shallow: true,
      });

      // console.log(` IPreviewFieldActiveBody documentId, locale, fieldName`, { documentId, locale, fieldName });
    };

    if (typeof window !== "undefined") {
      // @ts-ignore
      window.addEventListener(CLIENT_EVENTS.previewEdit, handler);
    }

    return () => {
      // @ts-ignore
      window.removeEventListener("message", handler);
    };
  }, [currentProjectId]);

  return null;
};
