import {
  Badge,
  Button,
  EBadgePosition,
  IconBriefcase,
  IconButton,
  IconChevronDown,
  IconCog,
  IconCross,
  IconDocuments,
  IconProjectsFolder,
  IconSearch,
  Img,
  Preview,
  SearchInput,
  Tooltip,
} from "@caisy/league";
import { NextRouter, useRouter } from "next/router";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useCurrentGroupId } from "../../../hooks/current-project-id/useCurrentGroupId";
import { useCurrentOrganizationId } from "../../../hooks/current-project-id/useCurrentOrganizationId";
import { useCurrentProjectId } from "../../../hooks/current-project-id/useCurrentProjectId";
import { I18n } from "../../../provider/i18n";
import { useI18nString } from "../../../provider/i18n/useI18nString";
import { useAuthentication } from "../../../stores/authentication/useAuthentication";
import { useMemberShip } from "../../../stores/membership/useMembership";
import getMemberRoleI18N, { IMemberRole } from "../../../utils/getMemberRoleI18N";
import { SDivider } from "../../atom/divider/SDivider";
import { IAddGroupModal } from "../../page/management/add-new-modal/AddNewItemModal";
import { getPlaceholderLettersByName } from "./getPlaceholderLettersByName";
import { IOrganizationMenuEmpty } from "./OrganizationMenuEmpty";
import { OrganizationMenuSub } from "./OrganizationMenuSub";
import { SOrganizationMenuGroupsAndProjects } from "./styles/SOrganizationMenuGroupsAndProjects";
import { SOrganizationMenuHeader } from "./styles/SOrganizationMenuHeader";
import { SOrganizationMenuHeaderSettingsButton } from "./styles/SOrganizationMenuHeaderSettingsButton";
import { SOrganizationMenuHeaderSwitchButton } from "./styles/SOrganizationMenuHeaderSwitchButton";
import { SOrganizationMenuHeaderTitle } from "./styles/SOrganizationMenuHeaderTitle";
import { SOrganizationMenuHeaderWrapper } from "./styles/SOrganizationMenuHeaderWrapper";
import { SOrganizationMenuMainWrapper } from "./styles/SOrganizationMenuMainWrapper";
import { SOrganizationMenuSearch } from "./styles/SOrganizationMenuSearch";
import { SOrganizationMenuSubOrganizations } from "./styles/SOrganizationMenuSubOrganizations";
import fuzzysearch from "fuzzysearch";
import { useControlKeyList } from "../../../hooks/useControlKeyList";
import { CreateProjectModal } from "./create-project-modal/CreateProjectModal";
import sortMembershipItems from "../../../utils/sortMembershipItems";
import { paramsToUrlString } from "../../../utils/getHrefWithParams";
import { usePermission } from "../../../stores/permission/usePermission";
import { hasPermissions } from "../../../utils/hasPermissions";

interface IOrganizationMenu {
  onClose: () => void;
  addNewModalProps: IAddGroupModal;
  setAddNewModalProps: React.Dispatch<React.SetStateAction<IAddGroupModal>>;
}

const organizationsEmptyProps: IOrganizationMenuEmpty = {
  title: <I18n selector="nav.organization_switcher_noOrganization" fallback="You don’t have an organisation yet" />,
  label: (
    <I18n
      selector="nav.organization_switcher_noOrganizationMessage"
      fallback="Create a first organization using the button below"
    />
  ),
  emptyType: "organizations",
  size: "large",
};

const groupsEmptyProps: IOrganizationMenuEmpty = {
  title: <I18n selector="nav.organization_switcher_noGroup" fallback="The organisation doesn’t have a group yet" />,
  label: (
    <I18n selector="nav.organization_switcher_noGroupMessage" fallback="Create a first group using the button below" />
  ),
  emptyType: "groups",
  size: "medium",
};

const projectsEmptyProps: IOrganizationMenuEmpty = {
  title: <I18n selector="nav.organization_switcher_noProject" fallback="The group doesn’t have a project yet" />,
  label: (
    <I18n
      selector="nav.organization_switcher_noProjectMessage"
      fallback="Create a first project using the button below"
    />
  ),
  emptyType: "projects",
  size: "medium",
};

const projectsEmptyNoSelectedProps: IOrganizationMenuEmpty = {
  title: <I18n selector="nav.organization_switcher_noGroupSelected" fallback="No group selected" />,
  label: (
    <I18n
      selector="nav.organization_switcher_noProjectMessage"
      fallback="Create a first project using the button below"
    />
  ),
  emptyType: "projects",
  size: "medium",
};

const searchEmptyProps: IOrganizationMenuEmpty = {
  title: (
    <I18n
      selector="nav.organization_switcher_searchProjectsMessage"
      fallback="Start typing to search projects across all organisations"
    />
  ),
  emptyType: "search",
  size: "small",
};

const changeProject = (router: NextRouter, projectId: string) => {
  let newPath = `/project/documents/document${paramsToUrlString({ projectId })}`;
  if (router.pathname.endsWith("assets")) {
    newPath = `/project/assets${paramsToUrlString({ projectId })}`;
  } else if (router.pathname.endsWith("releases")) {
    newPath = `/project/releases${paramsToUrlString({ projectId })}`;
  } else if (router.pathname.endsWith("playground")) {
    newPath = `/project/playground${paramsToUrlString({ projectId })}`;
  } else if (router.pathname.endsWith("settings/members")) {
    newPath = `/project/settings/members${paramsToUrlString({ projectId })}`;
  } else if (!router.pathname.includes("profile") && router.pathname.endsWith("[tab_name]")) {
    const chunks = router.asPath.split("?")[0].split("project/");
    newPath = chunks[0] + "project/" + chunks[1] + paramsToUrlString({ projectId });
  }

  router.push(newPath, undefined, {
    shallow: true,
  });
};

export const OrganizationMenu: FC<IOrganizationMenu> = ({ onClose, addNewModalProps, setAddNewModalProps }) => {
  const projectId = useCurrentProjectId();
  const groupId = useCurrentGroupId();
  const organizationId = useCurrentOrganizationId();
  const { user } = useAuthentication();
  const { getPermissions } = usePermission();

  const { projects, groups, organizations, projectWithInheritance, createGroup, createOrganization } = useMemberShip();
  const currentOrganizationDirect =
    organizations[organizationId] ||
    organizations[groups[groupId]?.organizationId] ||
    organizations[projects[projectId]?.organizationId];
  const currentOrganization = currentOrganizationDirect || (projectId && projectWithInheritance?.organization);
  const currentGroupDirect = groups[groupId] || groups[projects[projectId]?.groupId];
  const currentGroup = currentGroupDirect || (projectId && projectWithInheritance?.group);
  const router = useRouter();
  const [selectedOrganization, setSelectedOrganization] = useState(currentOrganization?.organizationId || "");
  const [selectedGroup, setSelectedGroup] = useState(currentGroup?.groupId || "");
  const [organizationOpen, setOrganizationOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [canCreateGroup, setCanCreateGroup] = useState(false);
  const [canCreateProject, setCanCreateProject] = useState(false);
  // const [showImportFromUrlModal, setShowImportFromUrlModal] = useState(false);

  const i18nStringProjects = useI18nString({
    selector: "nav.organization_switcher_projects",
    fallback: "Projects",
  });

  const groupsProjects = useMemo(() => {
    return Object.values(projects).reduce((acc, project) => {
      if (project.groupId) {
        acc[project.groupId] = acc[project.groupId] ? [...acc[project.groupId], project] : [project];
      }
      return acc;
    }, {});
  }, [projects]);

  const organizationsGroups = useMemo(() => {
    return Object.values(groups).reduce((acc, group) => {
      const item = {
        ...group,
        labelText: `${groupsProjects[group.groupId]?.length || 0} ${i18nStringProjects}`,
      };
      if (group.organizationId) {
        acc[group.organizationId] = acc[group.organizationId] ? [...acc[group.organizationId], item] : [item];
      } else {
        acc["unorganised"] = acc["ungrouped"] ? [...acc["ungrouped"], item] : [item];
      }
      return acc;
    }, {});
  }, [groups]);

  const ungroupedProjects = useMemo(() => {
    const ungroupedProjects = {};
    Object.values(projects).forEach((project) => {
      if (!project.groupId) {
        if (!ungroupedProjects[project.organizationId]) {
          ungroupedProjects[project.organizationId] = [project];
        } else {
          ungroupedProjects[project.organizationId] = [...ungroupedProjects[project.organizationId], project];
        }
      }
    });

    return ungroupedProjects;
  }, [projects]);

  useEffect(() => {
    setSelectedOrganization(currentOrganization?.organizationId || "");
  }, [projectId, groupId, organizationId, projectWithInheritance]);

  useEffect(() => {
    const hasUngroupedProjects = !!ungroupedProjects[selectedOrganization];

    if (
      ((currentGroup == null || selectedGroup == "ungrouped") && selectedOrganization) ||
      (currentGroup && currentGroup.organizationId != selectedOrganization)
    ) {
      if (selectedGroup) return;
      setSelectedGroup(
        hasUngroupedProjects ? "ungrouped" : organizationsGroups[selectedOrganization]?.[0]?.groupId || "",
      );
      return;
    }

    if (currentOrganization?.organizationId && projectId && selectedGroup && selectedGroup != currentGroup.groupId) {
      setSelectedGroup(selectedGroup);
      return;
    }

    if (currentOrganization?.organizationId && projectId) {
      setSelectedGroup(currentGroup?.groupId || "ungrouped");
      return;
    }

    if (currentGroup?.groupId && selectedGroup === null) {
      setSelectedGroup(currentGroup?.groupId || "");
      return;
    }

    if (!projectId && !groupId && selectedOrganization) {
      setSelectedGroup(
        hasUngroupedProjects ? "ungrouped" : organizationsGroups[selectedOrganization]?.[0]?.groupId || "",
      );
      return;
    }
  }, [
    currentGroup,
    selectedGroup,
    projectId,
    groupId,
    organizationId,
    projectWithInheritance,
    selectedOrganization,
    organizationsGroups,
    ungroupedProjects,
    currentOrganization,
  ]);

  const getItems = (object) => {
    const items = [];
    for (const property in object) {
      items.push(object[property]);
    }
    return items;
  };

  useEffect(() => {
    addNewModalProps.visible && onClose();
  }, [addNewModalProps]);

  const closeModal = () => {
    setAddNewModalProps({ ...addNewModalProps, visible: false });
  };

  useEffect(() => {
    if (!selectedOrganization) {
      if (!getItems(organizations)?.length) {
        setSearchOpen(true);
      }
      setOrganizationOpen(true);
    }
  }, [selectedOrganization]);

  const getOrganizationImage = () => {
    const organization = organizations[selectedOrganization] || currentOrganization;
    return organization?.logoAssetUrl
      ? {
          image: <Img lazyload={false} src={organization.logoAssetUrl!} resolution={48} />,
        }
      : {
          text: getPlaceholderLettersByName(`${organization?.name ? organization.name : ""}`),
        };
  };

  // ALL OF THESE NEED TO BE FETCHED DYNAMICALLY SINCE THEY DEPEND ON THE SELECTED ORGANIZATION/GROUP

  const checkGroupPermissions = async ({ organizationId }) => {
    const permissionSet = await getPermissions({ userId: user.id, organizationId });
    const permission = { action: "create", object: "group" };
    return hasPermissions({ permissionSet, permission });
  };

  const checkProjectPermissions = async ({ groupId, organizationId }) => {
    const permissionSet =
      groupId && groupId !== "ungrouped"
        ? await getPermissions({ userId: user.id, groupId })
        : await getPermissions({ userId: user.id, organizationId });
    const permission = { action: "create", object: "project" };
    return hasPermissions({ permissionSet, permission });
  };

  useEffect(() => {
    if (!selectedOrganization) return setCanCreateGroup(false);
    setCanCreateGroup(false);
    checkGroupPermissions({ organizationId: selectedOrganization }).then(setCanCreateGroup);
  }, [selectedOrganization]);

  useEffect(() => {
    if (!selectedGroup && !selectedOrganization) return setCanCreateProject(false);
    setCanCreateProject(false);
    checkProjectPermissions({ groupId: selectedGroup, organizationId: selectedOrganization }).then(setCanCreateProject);
  }, [selectedGroup, selectedOrganization]);

  const onOrganizationItemClick = async (item) => {
    setSelectedOrganization(item.organizationId);
    setSelectedGroup(null);
    setOrganizationOpen(false);
  };

  const onProjectItemClick = (item) => {
    changeProject(router, item.projectId);
    onClose();
  };

  const getGroupItems = () => {
    let items = [];
    if (organizationsGroups?.[selectedOrganization]) {
      items = [...organizationsGroups[selectedOrganization]];
    }
    if (ungroupedProjects?.[selectedOrganization]?.length) {
      items.unshift({
        name: <I18n selector="nav.organization_switcher_ungrouped" fallback="Ungrouped" />,
        groupId: "ungrouped",
        labelText: `${ungroupedProjects[selectedOrganization].length} ${i18nStringProjects}`,
      });
    }
    return items;
  };

  const getProjectsItems = () => {
    if (selectedGroup === "ungrouped") {
      return ungroupedProjects?.[selectedOrganization] || [];
    } else {
      return groupsProjects?.[selectedGroup] || [];
    }
  };

  const createNewOrganization = () => {
    setAddNewModalProps({
      ...addNewModalProps,
      title: <I18n selector="nav.organization_switcher_newOrganization" fallback="Create New Organization" />,
      icon: <IconBriefcase />,
      visible: true,
      placeholder: {
        fallback: "organization name",
        selector: "nav.organization_switcher_organizationName",
      },
      inputTitle: <I18n selector="nav.organization_switcher_organizationName" fallback="organization name" />,
      onSubmit: async (data) => {
        const response = await createOrganization({
          name: data.name,
          userId: user.id,
        });

        if (!response) return closeModal();

        router.push(
          `/organization/settings/members${paramsToUrlString({
            organizationId: response.organizationId,
          })}`,
          undefined,
          { shallow: true },
        );

        closeModal();
      },
      onCancel: closeModal,
    });
  };

  const createNewGroup = () => {
    setAddNewModalProps({
      ...addNewModalProps,
      title: <I18n selector="nav.organization_switcher_newGroup" fallback="Create New Group" />,
      visible: true,
      placeholder: {
        fallback: "group name",
        selector: "nav.organization_switcher_groupName",
      },
      inputTitle: <I18n selector="nav.organization_switcher_groupName" fallback="group name" />,
      onSubmit: async (data) => {
        const response = await createGroup({
          group: { name: data.name },
          organizationId: selectedOrganization,
          userId: user.id,
        });
        router.push(
          `/group/settings/members${paramsToUrlString({
            groupId: response.groupId,
          })}`,
          undefined,
          { shallow: true },
        );

        closeModal();
      },
      onCancel: closeModal,
    });
  };

  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  // for the first part of this switch statement, this is some logic since if the user has just a few project the search might
  // be overkill so we just show them all in a single view that is faster for the user
  const filteredItems: any = useMemo(() => {
    return searchValue == "" && Object.keys(projects)?.length < 5
      ? sortMembershipItems(Object.values(projects), "")
      : searchValue != "" &&
          sortMembershipItems(
            Object.values(projects).filter(
              (item) =>
                fuzzysearch(searchValue.toLowerCase(), item.name.toLowerCase()) ||
                (item.description &&
                  item.description != "" &&
                  fuzzysearch(searchValue.toLowerCase(), item.description.toLowerCase())) ||
                searchValue == item.projectId ||
                searchValue == item.groupId ||
                searchValue == item.organizationId,
            ),
            searchValue,
          );
  }, [searchValue, projects]);

  const { selection, listRef, handleBlur, handleKeyDown } = useControlKeyList({
    onEnter: onProjectItemClick,
    items: filteredItems,
  });

  const i18nSearchProjectsPlaceholder = useI18nString({
    fallback: "Search projects globally",
    selector: "nav.organization_switcher_searchProjectsGlobally",
  });

  return (
    <SOrganizationMenuMainWrapper className="ob-20c">
      <div className="ob-27c" onKeyDown={handleKeyDown} onBlur={handleBlur}>
        <SOrganizationMenuHeader searchOpen={searchOpen}>
          <IconButton
            size="small"
            type={searchOpen ? "primary" : "secondary"}
            onClick={() => {
              setSearchOpen((prev) => !prev);
              setSearchValue("");
            }}
          >
            {searchOpen ? <IconCross size={20} /> : <IconSearch size={20} />}
          </IconButton>
          <SDivider dividerHeight="12px" />
          {searchOpen && (
            <SearchInput
              autoFocus
              onClose={() => setSearchValue("")}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={i18nSearchProjectsPlaceholder}
            />
          )}

          {Object.keys(organizations || {}).length && !searchOpen && selectedOrganization ? (
            <>
              {getOrganizationImage() && <Preview {...getOrganizationImage()} size={40} />}
              {(organizations[selectedOrganization]?.name || currentOrganization?.name) && (
                <SOrganizationMenuHeaderTitle>
                  {organizations[selectedOrganization]?.name || currentOrganization.name}
                </SOrganizationMenuHeaderTitle>
              )}
            </>
          ) : (
            !searchOpen &&
            !selectedOrganization && (
              <>
                <Preview icon={<IconBriefcase size={24} />} size={40} />
                <SOrganizationMenuHeaderTitle secondary>
                  <I18n selector="nav.organization_switcher_selectOrganization" fallback="Select an organization" />
                </SOrganizationMenuHeaderTitle>
              </>
            )
          )}
          {!!Object.keys(organizations || {}).length && !searchOpen && (
            <SOrganizationMenuHeaderWrapper>
              {organizations[selectedOrganization]?.roleByUser?.title && (
                <Badge
                  value={getMemberRoleI18N(
                    (organizations[selectedOrganization].roleByUser.title.toLowerCase() as IMemberRole) || "viewer",
                  )}
                  size="medium"
                  position={EBadgePosition.Center}
                  type="regular"
                />
              )}
              {organizations[selectedOrganization] && (
                <SOrganizationMenuHeaderSettingsButton
                  onClick={() => {
                    router.push(
                      `/organization/settings/general${paramsToUrlString({
                        organizationId: selectedOrganization,
                      })}`,
                      undefined,
                      { shallow: true },
                    );

                    onClose();
                  }}
                >
                  <Tooltip
                    color="black"
                    content={<I18n selector="nav.nav_items_setting" fallback="Settings" />}
                    placement="top"
                  >
                    <IconCog size={16} />
                  </Tooltip>
                </SOrganizationMenuHeaderSettingsButton>
              )}
              {selectedOrganization && (
                <SOrganizationMenuHeaderSwitchButton organizationOpen={organizationOpen}>
                  <Button
                    onClick={() => {
                      setOrganizationOpen((prev) => !prev);
                    }}
                  >
                    <I18n selector="nav.organization_switcher_switchOrganization" fallback="Switch organisation" />
                    <IconChevronDown size={16} />
                  </Button>
                </SOrganizationMenuHeaderSwitchButton>
              )}
            </SOrganizationMenuHeaderWrapper>
          )}
        </SOrganizationMenuHeader>
        {searchOpen && (
          <SOrganizationMenuSearch>
            <OrganizationMenuSub
              hideSearch
              itemHeight={80}
              listHeight={447}
              onClose={onClose}
              noHeader
              emptyProps={searchEmptyProps}
              items={filteredItems || []}
              selectedItem={projectId || ""}
              key="searchProjects"
              onItemClick={onProjectItemClick}
              onClickSwitchButton={onProjectItemClick}
              title="searchProjects"
              ref={listRef}
              selection={selection}
            />
          </SOrganizationMenuSearch>
        )}
      </div>
      {organizationOpen && !searchOpen && (
        <SOrganizationMenuSubOrganizations>
          <OrganizationMenuSub
            itemHeight={80}
            listHeight={320}
            ButtonContent={
              <I18n selector="nav.organization_switcher_newOrganization" fallback="CREATE NEW ORGANIZATION" />
            }
            onButtonClick={createNewOrganization}
            onItemClick={onOrganizationItemClick}
            items={getItems(organizations)}
            selectedItem={selectedOrganization}
            onClickSwitchButton={onOrganizationItemClick}
            onClose={onClose}
            emptyProps={{
              ...organizationsEmptyProps,
              // every user always can create organizations so there is no need to check for permissions
              buttonElement: (
                <Button onClick={createNewOrganization} type="primary" size="medium">
                  <I18n selector="nav.organization_switcher_createOrganization" fallback="Create Organization" />
                </Button>
              ),
            }}
            title="Organizations"
            noHeader
            searchPlaceholder={{
              selector: "nav.organization_switcher_searchOrganizations",
              fallback: "Search organizations",
            }}
          />
        </SOrganizationMenuSubOrganizations>
      )}
      {!organizationOpen && (
        <SOrganizationMenuGroupsAndProjects>
          <div className="ob-28c">
            <OrganizationMenuSub
              itemHeight={68}
              listHeight={canCreateGroup ? 266 : 266 + 72}
              onClose={onClose}
              emptyProps={{
                ...groupsEmptyProps,
                buttonElement: canCreateGroup && (
                  <Button onClick={createNewGroup} type="primary" size="medium">
                    <I18n selector="nav.organization_switcher_createGroup" fallback="Create Group" />
                  </Button>
                ),
              }}
              items={getGroupItems()}
              selectedItem={selectedGroup}
              headerIcon={<IconProjectsFolder size={20} />}
              key="groups"
              menuWidth="287px"
              ButtonContent={
                canCreateGroup && <I18n selector="nav.organization_switcher_newGroup" fallback="CREATE NEW GROUP" />
              }
              onItemClick={(item) => {
                setSelectedGroup(item.groupId);
              }}
              onButtonClick={createNewGroup}
              title={<I18n selector="nav.organization_switcher_groups" fallback="Groups" />}
              searchPlaceholder={{
                selector: "nav.organization_switcher_searchGroups",
                fallback: "Search groups",
              }}
            />
          </div>

          <SDivider dividerHeight="100%" />

          <div className="ob-29c">
            <OrganizationMenuSub
              itemHeight={68}
              listHeight={canCreateProject ? 266 : 266 + 72}
              onClose={onClose}
              emptyProps={
                selectedGroup
                  ? {
                      ...projectsEmptyProps,
                      buttonElement: canCreateProject && (
                        <Button onClick={() => setShowCreateProjectModal(true)} type="primary" size="medium">
                          <I18n selector="nav.organization_switcher_createProject" fallback="Create Project" />
                        </Button>
                      ),
                    }
                  : {
                      ...projectsEmptyNoSelectedProps,
                      label: canCreateProject ? (
                        <I18n
                          selector="nav.organization_switcher_noProjectMessage"
                          fallback="Create a first project using the button below"
                        />
                      ) : (
                        <I18n
                          selector="nav.organization_switcher_selectGroup"
                          fallback="Select group to see projects inside"
                        />
                      ),
                      buttonElement: canCreateProject && (
                        <Button onClick={() => setShowCreateProjectModal(true)} type="primary" size="medium">
                          <I18n selector="nav.organization_switcher_createProject" fallback="Create Project" />
                        </Button>
                      ),
                    }
              }
              onItemClick={onProjectItemClick}
              onClickSwitchButton={onProjectItemClick}
              loading={selectedGroup === null}
              items={getProjectsItems()}
              headerIcon={<IconDocuments size={20} />}
              menuWidth="429px"
              ButtonContent={
                canCreateProject && (
                  <I18n selector="nav.organization_switcher_newProject" fallback="CREATE NEW PROJECT" />
                )
              }
              selectedItem={projectId}
              onButtonClick={() => setShowCreateProjectModal(true)}
              title={<I18n selector="nav.organization_switcher_projects" fallback="Projects" />}
              searchPlaceholder={{
                selector: "nav.organization_switcher_searchProjects",
                fallback: "Search projects",
              }}
            />
          </div>

          <CreateProjectModal
            onClose={() => {
              onClose();
              setShowCreateProjectModal(false);
            }}
            organizationId={selectedOrganization}
            groupId={selectedGroup}
            visible={showCreateProjectModal}
          />
        </SOrganizationMenuGroupsAndProjects>
      )}
    </SOrganizationMenuMainWrapper>
  );
};
