import {
  PEER_MESSAGE_TYPE_ACTIVE_DOCUMENT_CHANGE,
  PEER_MESSAGE_TYPE_ACTIVE_URL,
  PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON,
  PEER_MESSAGE_TYPE_FIELD_DISSMISS,
  PEER_MESSAGE_TYPE_FIELD_INACTIVE_ON,
  PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON,
  PEER_MESSAGE_TYPE_FIELD_UNSUBSCRIBE_ON,
  PEER_MESSAGE_TYPE_HOT_FIELD_UPDATE,
  PEER_MESSAGE_TYPE_AWARENESS,
  PEER_MESSAGE_TYPE_MESSAGE_SYNC_ACKNOWLEDGEMENT,
  PEER_MESSAGE_TYPE_MESSAGE_SYNC_REQUEST,
  PEER_MESSAGE_TYPE_MESSAGE_SYNC_UPDATE,
  PEER_MESSAGE_TYPE_PEER_MUTATION,
  PEER_MESSAGE_TYPE_PREVIEW_FIELD_ACTIVE,
  PEER_MESSAGE_TYPE_PREVIEW_FIELD_UPDATE,
} from "./constants";
import { ICollaborationState, IPeer } from "./types";
import { handleFieldSubscribeOn } from "./peer-message-handler/handleFieldSubscribeOn";
import { handleAwareness } from "./peer-message-handler/handleAwareness";
import { handleFieldActiveOn } from "./peer-message-handler/handleFieldActiveOn";
import { handleFieldInactiveOn } from "./peer-message-handler/handleFieldInactiveOn";
import { handleHotFieldUpdate } from "./peer-message-handler/handleHotFieldUpdate";
import { handleFieldUnsubscribeOn } from "./peer-message-handler/handleFieldUnsubscribeOn";
import { handleActiveDocumentChange } from "./peer-message-handler/handleActiveDocumentChange";
import { handleActiveURL } from "./peer-message-handler/handleActiveURL";
import { handlePeerMutation } from "./peer-message-handler/handlePeerMutation";
import { handleFieldDissmiss } from "./peer-message-handler/handleFieldDissmiss";
import { handleSyncAcknowledgement } from "./peer-message-handler/handleSyncAcknowledgement";
import { handleSyncRequest } from "./peer-message-handler/handleSyncRequest";
import { handleSyncUpdate } from "./peer-message-handler/handleSyncUpdate";
import { handlePreviewFieldActive } from "./peer-message-handler/handlePreviewFieldActive";

export const PEER_MESSAGE_TYPE_MAP = {
  [PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON]: "Field Subscribe On",
  [PEER_MESSAGE_TYPE_FIELD_UNSUBSCRIBE_ON]: "Field Unsubscribe On",
  [PEER_MESSAGE_TYPE_MESSAGE_SYNC_REQUEST]: "Message Sync Request",
  [PEER_MESSAGE_TYPE_MESSAGE_SYNC_ACKNOWLEDGEMENT]: "Message Sync Acknowledgement",
  [PEER_MESSAGE_TYPE_MESSAGE_SYNC_UPDATE]: "Message Sync Update",
  [PEER_MESSAGE_TYPE_FIELD_DISSMISS]: "Field Dismiss",
  [PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON]: "Field Active On",
  [PEER_MESSAGE_TYPE_FIELD_INACTIVE_ON]: "Field Inactive On",
  [PEER_MESSAGE_TYPE_ACTIVE_DOCUMENT_CHANGE]: "Active Document Change",
  [PEER_MESSAGE_TYPE_ACTIVE_URL]: "Active URL",
  [PEER_MESSAGE_TYPE_PEER_MUTATION]: "Peer Mutation",
  [PEER_MESSAGE_TYPE_HOT_FIELD_UPDATE]: "hot field update",
  [PEER_MESSAGE_TYPE_PREVIEW_FIELD_ACTIVE]: "preview field active",
  [PEER_MESSAGE_TYPE_PREVIEW_FIELD_UPDATE]: "preview field update",
};

// export const onPeerMessage = (state: ICollaborationState, peer: IPeer, data: Uint8Array, hasDelay = false) => {
export const onPeerMessage = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  if (!state.peers.find((localPeer) => peer.clientId === localPeer.clientId)) return;

  const peerMessageType = data[0];

  // some fake delay
  // debug only
  // if (state.ownClientId > peer.clientId && !hasDelay) {
  //   setTimeout(() => {
  //     onPeerMessage(state, peer, data, true);
  //   }, 100);
  //   return;
  // }

  // debug only
  // @ts-ignore

  window.c.collaboration.callstack_incoming = window.c.collaboration.callstack_incoming || [];
  PEER_MESSAGE_TYPE_MAP[peerMessageType] &&
    // @ts-ignore
    window.c.collaboration.callstack_incoming.push(PEER_MESSAGE_TYPE_MAP[peerMessageType]);

  switch (peerMessageType) {
    case PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON:
      handleFieldSubscribeOn(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_FIELD_UNSUBSCRIBE_ON:
      handleFieldUnsubscribeOn(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_MESSAGE_SYNC_REQUEST:
      handleSyncRequest(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_MESSAGE_SYNC_ACKNOWLEDGEMENT:
      handleSyncAcknowledgement(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_MESSAGE_SYNC_UPDATE:
      handleSyncUpdate(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_FIELD_DISSMISS:
      handleFieldDissmiss(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_AWARENESS:
      handleAwareness(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON:
      handleFieldActiveOn(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_FIELD_INACTIVE_ON:
      handleFieldInactiveOn(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_ACTIVE_DOCUMENT_CHANGE:
      handleActiveDocumentChange(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_ACTIVE_URL:
      handleActiveURL(state, peer, data);
      break;
    case PEER_MESSAGE_TYPE_PEER_MUTATION:
      handlePeerMutation(data);
      break;
    case PEER_MESSAGE_TYPE_HOT_FIELD_UPDATE:
      handleHotFieldUpdate(state, data);
      break;
    case PEER_MESSAGE_TYPE_PREVIEW_FIELD_ACTIVE:
      handlePreviewFieldActive(state, peer, data);
      break;
    default:
      console.log("Unknown peer message type:", peerMessageType);
      break;
  }
};
