import { Button } from "@caisy/league";
import { useState } from "react";
import { I18n } from "../../../../../provider/i18n";
import { AddMemberModal } from "../../add-member-modal/AddMemberModal";
import { useProjectMember } from "../../../../../stores/membership/useProjectMember";
import { Secured } from "../../../../../services/security";
import { useCurrentProjectId } from "../../../../../hooks/current-project-id/useCurrentProjectId";

export const ProjectMembersNavChildren = () => {
  const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
  const { createProjectMember, loadProjectMembersWithAggregation } = useProjectMember();
  const projectId = useCurrentProjectId();

  const onCancelAddMemberModal = () => {
    setAddMemberModalVisible(false);
  };

  const loadData = async () => {
    await loadProjectMembersWithAggregation({ projectId });
  };

  const onAddMemberModalSubmit = async (data) => {
    await createProjectMember({
      emailAddress: data.email,
      projectId,
      systemRole: "viewer",
    });
    loadData();
    setAddMemberModalVisible(false);
  };

  return (
    <Secured action="update" entity="project">
      <Button
        size="medium"
        type="primary"
        onClick={() => {
          setAddMemberModalVisible(true);
        }}
      >
        <I18n selector="projectManagementMembers.assignNewMember" fallback="Assign new member" />
        <AddMemberModal
          title={<I18n selector="projectManagementMembers.addMemberToProject" fallback="Add Member to Project" />}
          visible={addMemberModalVisible}
          onCancel={onCancelAddMemberModal}
          onSubmit={onAddMemberModalSubmit}
        />
      </Button>
    </Secured>
  );
};
