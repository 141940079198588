import { client } from "../../utils/client";
import { getUser } from "src/graphql/queries/getUser.gql";
import firebase from "firebase/app";
import "firebase/auth";
import * as Sentry from "@sentry/browser";
import { message } from "@caisy/league";
import { mutation_setPreferredLanguage } from "../../graphql/mutations/setPreferredLanguage.gql";
import produce from "immer";
import { IUserProfileInput } from "../../interfaces/generated";
import { updateUserProfile } from "../../graphql/mutations/updateUserProfile.gql";
import { checkFirebaseIfUserIsThere } from "../../services/firebase";
import { getUserInformation } from "../../graphql/queries/getUser.gql copy";

const uuidRegex = new RegExp(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g);

const config = {
  authDomain: process.env.NEXT_PUBLIC_FIRE_AUTHDOMAIN,
  apiKey: process.env.NEXT_PUBLIC_FIRE_APIKEY,
};

if (!firebase?.apps?.length) {
  firebase.initializeApp(config);
}

const CaisyUserIdCookieName = "caisyuserid";

function setUserIdCookie(name, value, days) {
  const domain =
    window.location.hostname.split(".").length >= 2 && window.location.hostname.split(".").slice(-2).join(".");

  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    name + "=" + (value || "") + expires + `; path=/; SameSite=Lax;${domain ? ` domain=${domain};` : ""}`;
}

function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

// interface INamedItemWithIdNMobxTypeFragment {
//   id: string | null;
//   name: string | null;
//   getName: () => string | null;
//   getId: () => string | null;
// }

// interface IUserAccessToken {
//   id: string;
//   name: string;
//   createdAt: Date;
// }

export interface IUser {
  id: string;
  email: string | null;
  preferredUILanguage: string | null;
  preferredUITheme: number | null;
  displayName: string | null;
  photoURL: string | null;
}

export interface IUseAuthentication {
  userId: string;
  user: IUser | null;
  accessToken: string | null;
  isLoggedIn: boolean;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  checkLogin: () => Promise<boolean>;
  setPreferredLanguage: (args_0: { language: string }) => Promise<void>;
  updateUserProfile: (profileUpdates: IUserProfileInput) => Promise<{ success: boolean }>;
}

export interface IAuthenticationState {
  authentication: IUseAuthentication;
}

export const createAuthenticationSlice = (
  set: (cb: (state: IAuthenticationState) => IAuthenticationState, boolean, string) => void,
  // get: () => IAuthenticationState,
) => ({
  authentication: {
    userId: undefined,
    user: null,
    accessToken: null,
    isLoggedIn: false,
    isLoading: false,
    login: async function (email: string, password: string) {
      if (typeof window !== "undefined") {
        await firebase.auth().signInWithEmailAndPassword(email, password);
      }
    },
    logout: async () => {
      set(
        produce((state) => {
          state.authentication.isLoading = false;
          state.authentication.user = null;
        }),
        false,
        "auth/logout",
      );

      if (typeof window !== "undefined") {
        eraseCookie(CaisyUserIdCookieName);
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        firebase
          .auth()
          .signOut()
          .then(function () {
            // Sign-out successful.
            //  window replace and not router.push on logout to force refetch from proxy for coperate enterprise login
            if (window.location.host.includes("localhost")) {
              const r = confirm("logout?");
              if (r == true) {
                window.location.replace("/app/login");
                try {
                  window.localStorage.removeItem("accounts:accessToken");
                  window.localStorage.removeItem("accounts:time");
                } catch {}
              }
            } else {
              window.location.replace("/app/login");
              try {
                window.localStorage.removeItem("accounts:accessToken");
                window.localStorage.removeItem("accounts:time");
              } catch {}
            }
          })
          .catch(function (error) {
            console.log(`firebase.auth().signOut() error`, error);
            // An error happened.
          });
      }
    },
    setPreferredLanguage: async ({ language }: { language: string }) => {
      await client.mutate({
        mutation: mutation_setPreferredLanguage,
        variables: { language },
      });
      set(
        produce((state) => {
          state.authentication.user.preferredUILanguage = language;
        }),
        false,
        "auth/setPreferredLanguage",
      );
    },
    updateUserProfile: async (profileUpdates: IUserProfileInput) => {
      try {
        const { data } = await client.mutate({
          mutation: updateUserProfile,
          variables: { input: { profile: profileUpdates } },
        });
        if (data?.UpdateUserProfileRequest?.success) {
          set(
            produce((state) => {
              state.authentication.user.displayName = profileUpdates.displayName;
              state.authentication.user.photoURL = profileUpdates.photoURL;
            }),
            false,
            "auth/updateUserProfile",
          );
          return { success: true };
        }
      } catch (error) {
        console.log({ error });
        message.error("Something went wrong!");
      }
    },
    checkLogin: async () => {
      try {
        const w = window as any;
        w && typeof w.clarity == "function" && w.clarity("consent");
        const [getUserRes, getUserInformationRes] = await Promise.all([
          client.query({
            query: getUser,
          }),
          client.query({
            query: getUserInformation,
          }),
        ]);
        const { data } = getUserRes;

        if (data && data.getUser) {
          const { profile } = data.getUser;
          const userInformation = getUserInformationRes?.data?.GetUserInformation;
          const activeProjectId = userInformation?.activeProjectId;

          // console.log(` session, profile`, session, profile);
          // @todo get the user information and use active project form there and the ui language
          const user = {
            ...profile,
            preferredUILanguage: userInformation?.preferredUILanguage || null,
          };

          setUserIdCookie(CaisyUserIdCookieName, data.getUser.profile.id, 14);
          set(
            produce((state) => {
              state.authentication.isLoggedIn = true;
              state.authentication.user = user;
              state.authentication.userId = data.getUser.profile.id;
            }),
            false,
            "auth/checkLogin",
          );

          if (activeProjectId && activeProjectId != "") {
            if (typeof window !== "undefined") {
              if (activeProjectId && !window.location.href.match(uuidRegex)) {
                if (`${activeProjectId}`.match(uuidRegex)) {
                  window.location.replace(`/app/project/documents/document?project_id=${activeProjectId}`);
                }
              }
            }
          }
          const accessToken = localStorage.getItem("accounts:accessToken");
          if (accessToken) {
            set(
              produce((state) => {
                state.authentication.accessToken = `${accessToken}`;
              }),
              false,
              "auth/checkLogin/accessToken",
            );
          }
          try {
            if (typeof window !== "undefined") {
              Sentry.setUser({ email: data?.getUser?.profile?.email, id: data?.getUser?.profile?.id });
            }
          } catch (err) {
            console.log(`Sentry err:`, err);
          }
          return !!data.getUser;
        }
      } catch (err) {
        if (`${err.message}`.includes("No current Session found") || `${err.message}`.includes("401")) {
          console.info("No current Session found, waiting for refresh");

          setTimeout(() => {
            try {
              if (!checkFirebaseIfUserIsThere()) {
                if (typeof window !== "undefined") {
                  window.location.replace(`/app/login`);
                }
              }
            } catch (err) {
              console.error("No current Session found 3000ms timeout");
              console.log(`checkFirebaseIfUserIsThere err`, err);
            }
          }, 3000);
          // this will lead to kick out during session refetch
          // get().authentication.logout();
        } else {
          console.log(`login failed: `, err);
        }
        return false;
      }
    },
  },
});
