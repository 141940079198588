import produce from "immer";
import {
  addOrUpdateMemberFromState,
  createMember,
  deleteMember,
  getUserIdWithEmailAddress,
  updateMember,
} from "./common";
import { IAssignRoleResponse } from "../../interfaces/generated";
import { getProjectMembers } from "../../graphql/queries/getProjectMembers";
import { queryWithStaleCache } from "../../graphql/middleware/query-with-stale-cache";
import {
  ICreateProjectMemberInput,
  IDeleteProjectMemberInput,
  IUpdateProjectMemberInput,
  IUseProjectMemberState,
} from "./types";
import { client } from "../../utils/client";
import { getProjectMembersWithAggregation } from "../../graphql/queries/getProjectMembersWithAggregation.gql";

export const createProjectMemberSlice = (
  set: (cb: (state: IUseProjectMemberState) => IUseProjectMemberState, boolean, string) => void,
  get: () => IUseProjectMemberState,
) => ({
  projectMember: {
    isLoading: false,
    dataHash: undefined,
    pagination: {},
    members: {},
    membersWithAggregation: {},
    loadingMembersWithAggregation: false,
    loadProjectMembers: async ({ projectId, isLoadNext }: { projectId: string; isLoadNext?: boolean }) => {
      await queryWithStaleCache({
        uniquePrefix: "project-members",
        query: getProjectMembers,
        variables: {
          projectId,
          paginationArguments: {
            first: 20,
            ...(isLoadNext &&
              get().projectMember.pagination[projectId]?.lastProjectMemberId && {
                after: get().projectMember.pagination[projectId].lastProjectMemberId,
              }),
          },
        },
        onCacheMiss: () => {
          set(
            produce((state) => {
              if (isLoadNext && state.projectMember.pagination[projectId]) {
                state.projectMember.pagination[projectId].isNextPageLoading = true;
              } else {
                state.projectMember.isLoading = true;
              }
            }),
            false,
            "projectMember/getProjectMembers/start",
          );
        },
        onUpdate: ({ data, dataHash }) => {
          if (data.GetProjectMembers) {
            set(
              produce((state: IUseProjectMemberState) => {
                state.projectMember.isLoading = false;

                if (get().projectMember.dataHash != dataHash) {
                  state.projectMember.dataHash = dataHash;
                  if (!state.projectMember.pagination[projectId])
                    state.projectMember.pagination[projectId] = {
                      isNextPageLoading: false,
                      hasNextPage: null,
                      lastProjectMemberId: null,
                    };
                  state.projectMember.pagination[projectId].hasNextPage =
                    data.GetProjectMembers.connection.pageInfo.hasNextPage;
                  state.projectMember.pagination[projectId].isNextPageLoading = false;
                  state.projectMember.pagination[projectId].lastProjectMemberId =
                    data.GetProjectMembers.connection.pageInfo.endCursor;

                  data.GetProjectMembers.connection.edges.forEach((member) => {
                    const newMember = {
                      projectId,
                      roleId: member.node.role.roleId,
                      systemRole: member.node.role.systemRole,
                      emailAddress: member.node.user.email,
                      userId: member.node.user.userId,
                    };
                    addOrUpdateMemberFromState(
                      member.node.user.userId,
                      newMember,
                      state.projectMember.members,
                      projectId,
                    );
                  });
                }
              }),
              false,
              "projectMember/getProjectMembers/done",
            );
          }
        },
      });
    },
    createProjectMember: async ({
      projectId,
      roleId,
      userId,
      systemRole,
      emailAddress,
    }: ICreateProjectMemberInput): Promise<IAssignRoleResponse> => {
      const data = await createMember({ projectId, roleId, userId, systemRole, emailAddress });

      const key = data?.userId;

      if (key) {
        const newMember = {
          roleId,
          systemRole,
          emailAddress,
          userId: userId || data.userId,
          projectId,
        };
        const stateKey = projectId;

        set(
          produce((state: IUseProjectMemberState) => {
            addOrUpdateMemberFromState(key, newMember, state.projectMember.members, stateKey);
          }),
          false,
          "projectMember/createProjectMember",
        );
      }

      return data;
    },
    deleteProjectMember: async ({ projectId, userId, emailAddress }: IDeleteProjectMemberInput) => {
      const data = await deleteMember({ projectId, userId, emailAddress });

      const key = userId || getUserIdWithEmailAddress(emailAddress, get().projectMember.members[projectId]);

      set(
        produce((state: IUseProjectMemberState) => {
          if (state.projectMember.members?.[projectId]?.[key]) {
            delete state.projectMember.members[projectId][key];
          }

          if (state.projectMember.membersWithAggregation?.[projectId]?.[key]) {
            delete state.projectMember.membersWithAggregation[projectId][key];
          }
        }),
        false,
        "projectMember/createProjectMember",
      );

      return data;
    },
    updateProjectMember: async ({
      projectId,
      userId,
      roleId,
      systemRole,
      emailAddress,
      memberId,
    }: IUpdateProjectMemberInput) => {
      const data = await updateMember({ projectId, userId, roleId, systemRole, emailAddress, memberId });

      const key = data.userId || userId || data.memberId || memberId;

      if (key) {
        const id = userId || data.userId;
        const email = get().projectMember.members[projectId]?.[key]?.emailAddress;
        const updatedMember = {
          roleId,
          systemRole,
          emailAddress: email,
          userId: id,
          projectId,
        };
        const stateKey = projectId;
        set(
          produce((state: IUseProjectMemberState) => {
            if (state.projectMember.members[projectId]) {
              addOrUpdateMemberFromState(key, updatedMember, state.projectMember.members, stateKey);
            }

            if (state.projectMember.membersWithAggregation[projectId]) {
              const roleIndexToModify = state.projectMember.membersWithAggregation[projectId][
                key
              ].membersResponse.roles.findIndex((role) => role.projectId === projectId);

              state.projectMember.membersWithAggregation[projectId][key].membersResponse.roles[
                roleIndexToModify
              ].roleId = roleId;
              state.projectMember.membersWithAggregation[projectId][key].membersResponse.roles[
                roleIndexToModify
              ].systemRole = systemRole;
            }
          }),
          false,
          "projectMember/createProjectMember",
        );
      }

      return data;
    },
    getProjectMembers: ({ projectId }) => {
      return get().projectMember.members[projectId];
    },
    loadProjectMembersWithAggregation: async ({ projectId }) => {
      set(
        produce((state) => {
          state.projectMember.loadingMembersWithAggregation = true;
        }),
        false,
        "projectMember/loadProjectMembersWithAggregation/start",
      );

      const { data } = await client.query({
        query: getProjectMembersWithAggregation,
        variables: {
          projectId,
        },
        fetchPolicy: "no-cache",
      });

      set(
        produce((state: IUseProjectMemberState) => {
          if (!state.projectMember.membersWithAggregation[projectId]) {
            state.projectMember.membersWithAggregation[projectId] = {};
          }
          data.GetProjectMembersWithAggregation.connection.edges.forEach((edge) => {
            state.projectMember.membersWithAggregation[projectId][
              edge.node.membersResponse.user.userId || edge.node.membersResponse.user.memberId
            ] = edge.node;
          });

          state.projectMember.loadingMembersWithAggregation = false;
        }),

        false,
        "projectMember/loadProjectMembersWithAggregation/end",
      );
    },
  },
});
